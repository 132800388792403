import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import PurchaseForm from '../components/panel/PurchaseForm';
import ProductList from '../components/panel/ProductList';
import { Row, Col } from '../components/layout/grid';

import { purchaseProduct, getProductsInsideBasket, removeFromBasket, clearBasket } from "../utils/actions/product";

import CartIcon from '../assets/images/icons/cart.svg'
import DeliveryTruckIcon from '../assets/images/icons/delivery-truck.svg'
import BillingIcon from '../assets/images/icons/approve.svg'
import '../assets/css/panel.css'

class PanelPage extends React.Component  {

  state = {
    step: this.props.location.state && this.props.location.state.step ? this.props.location.state.step : 1,
    loading: false,
    message: '',
    errors: {},
    payment_method: 'cod',
    payment_method_title: 'Payer & la livraison',
    set_paid: false,
    billing: {
      first_name: '',
      last_name: '',
      address_1: '',
      address_2: '',
      city: '',
      state: '',
      postcode: '16000',
      country: 'DZ',
      phone: ''
    },
    shipping: {
      first_name: '',
      last_name: '',
      address_1: '',
      address_2: '',
      city: '',
      state: '',
      postcode: '',
      country: 'DZ'
    },
    line_items: []
  }

  /**
   * @name onRemoveItem
   * remove product from panel (localStorage)
   * and reinitialize state again to fetch other products
   * @param {integer} index of product on panel array
   */
   onRemoveItem = index => {
     removeFromBasket(index);
     this.initState();
   }

 /**
  * fill costumer billing details
  * @param target HTMLElement
  */
  setBilling = ({ target }) => {
    if(target.name === 'email' && target.name.length ===0) return;
    let value = target.value;
    this.setState(prevState => ({
      billing: {
        ...prevState.billing,
        [target.name]: value,
      }
    }));
  }

 /**
  * fill costumer shipping details
  * @param target HTMLElement
  */
  setShipping = ({ target }) => {
   this.setState(prevState => ({
     shipping: {
       ...prevState.shipping,
       [target.name]: target.value,
     }
   }));
 }

 /**
  * @name onMoveForward
  * move to the next step
  */
  onMoveForward = () => {
    this.setState(prevState => ({step: prevState.step+1}))
  }

  /**
   * @name onSubmit
   * send an order when costumer submit a purchasing form
   * make a request to server for buying a product
   * is ajax request to custom api with woocommerce
   * key customer rest api and wait until server response
   * then if user purchasing is successed empty all state
   * else if purchase fail will render a popup errors
   */
   onSubmit = async (e) => {
    e.preventDefault();
    let errors = {};
    if(!/^(0|0213|213)?[0-9]{8,9}$/.test(this.state.billing.phone.trim().replace(/\D/, '')))
      errors = {...errors, phone: 'Tapez un numero de tel valide SVP'}
    if(!this.state.billing.first_name.trim().length)
      errors = {...errors, first_name: 'Tapez votre nom SVP'}
    if(!this.state.billing.last_name.trim().length)
      errors = {...errors, last_name: 'Tapez votre nom SVP'}
    if(!this.state.billing.address_1.trim().length)
      errors = {...errors, address_1: 'Tapez votre adresse SVP'}

    let errorsKeys = Object.keys(errors).reverse();
    if( !errorsKeys.length ) {
      if(typeof window !== `undefined`) {
        window.fbq && window.fbq('track', 'Purchase',
          {
            value: 1,
            currency: 'DZD',
            content_type: 'product', // required property
            contents: this.state.line_items,
          }
        );
      }
      this.setState({ loading: true, errors: {}, })
      try {
        let res = await purchaseProduct({...this.state, step: null});
        this.setState({
          loading: false,
          message: `Mr/Mdm ${res.billing.first_name} ${res.billing.first_name}, vous avez effectue une commande de total ${res.total}${res.currency}`,
        })
        this.onMoveForward();
        clearBasket();
        if( typeof window !== `undefined` ) localStorage.panel = '[]';
      } catch (e) {
        this.setState({ loading: false, })
        alert('Ressayez une autre fois S.V.P')
      }
    }
    else {
       this.setState({errors})
       if( typeof window !== `undefined` )
         window.scrollTo(0, 150);
    }

  }

  /**
   * @name initState
   * initialize state with default value or from localStorage
   */
   initState = () => {
     let line_items = getProductsInsideBasket().map( item => ({
       product_id: item.product_id,
       quantity: item.quantity,
       meta_data: item.meta_data,
     }));
     this.setState({line_items})
   }

  /**
   * render the current block depend on step state
   */
   renderStep = () => {
     let products = getProductsInsideBasket();
     if(this.state.step === 1) return (
         <ProductList
           data={products}
           onRemoveItem={this.onRemoveItem}
           onClick={this.onMoveForward}
         />
       )
     else if ( this.state.step === 2 ) return (
         <PurchaseForm
           setShipping={this.setShipping}
           setBilling={this.setBilling}
           onSubmit={this.onSubmit}
           loading={this.state.loading}
           errors={this.state.errors}
         />
       )
     else return (
       <Row className='delivery-container center'>
         <Col s='12'>
           <img src={DeliveryTruckIcon} alt="delivery" className='delivery-container__image'/>
           <p className="delivery-container__message">
             {this.state.message}
           </p>
         </Col>
         <Col s='12'>
          <p className="delivery-container__backorder-infos ">
            Un problème avec le produits ? <br/>
            Ci-dessous les modalités de retour ! <br/>
            Dans quels cas vous pouvez effectuer un retour ?
          </p>
          <ul className="delivery-container__backorder-list p0 ml0">
            <li className="delivery-container__backorder-item mb0">Les retours sont acceptés dans un delai de 07 jours à partir de la date de livraison </li>
            <li className="delivery-container__backorder-item mb0">Le produit retourné droit être soit endommagé ou erroné</li>
            <li className="delivery-container__backorder-item mb0">Le produit doit être dans son emballage d'origine </li>
            <li className="delivery-container__backorder-item mb0">Le produit doit être neufs. Non utilisé et dans son état initial</li>
            <li className="delivery-container__backorder-item mb0">Le produit doit être complet</li>
            <li className="delivery-container__backorder-item mb0">Dans le cas de produit scellé, et s'il s'agit d'une erreur dans la commande le produit doit rester impérativement scellé lors de son retour</li>
            <li className="delivery-container__backorder-item mb0">Au-delà des 07 jours la garantie du vendeur s'appliquera lorsqu'elle est proposée</li>
          </ul>
          <p className="delivery-container__backorder-infos ">Comment retourné votre produit :</p>
          <ul className="delivery-container__backorder-list p0 ml0">
            <li className="delivery-container__backorder-item">appelez le service client </li>
            <li className="delivery-container__backorder-item">renseignez et découpez le cadre ci-dessous pour le joindre au colis à retourner. Il.nous permettra de reconnaître la raison du retour.</li>
            <li className="delivery-container__backorder-item">Expédiez votre colis via le service EMS de la poste à l'adresse suivante</li>
          </ul>
         </Col>
       </Row>
     )
   }

  componentDidMount() {
    this.initState();
  }

  render(){
    let { navigate, data } = this.props;
    let categoriesKeys = data.allWcProductsCategories.edges.map(({ node: category }) => category.name);

    return (
      <Layout
        navigate={navigate}
        categories={data.allWcProductsCategories || {edges: []}}>
        <SEO 
          title="Votre Panier"
          keywords={[`aalladine`, `ecommerce boutique`, ...categoriesKeys]} />
        <div className="panel-page-container">

          <Row>
            <ul className="timeline-container col s12 row center pl0 pr0">
              <li className={`timeline-container__item col s4 ${this.state.step===1? 'active' : 'checked'}`}>
                <span className="timeline-container__ball"></span>
                {
                  this.state.step===1 &&
                  <img className="timeline-container__image" alt='Panier' src={CartIcon} />
                }
              </li>
              <li className={`timeline-container__item col s4 ${this.state.step>2? 'checked' : this.state.step<2? '': 'active'}`}>
                <span className="timeline-container__ball"></span>
                {
                  this.state.step===2 &&
                  <img className="timeline-container__image" alt='Details' src={BillingIcon} />
                }
              </li>
              <li className={`timeline-container__item col s4 ${this.state.step!==3? '': 'checked'}`}>
                <span className="timeline-container__ball"></span>
                {
                  this.state.step===3 &&
                  <img className="timeline-container__image" alt='Livrer' src={DeliveryTruckIcon} />
                }
              </li>
            </ul>
          </Row>

          { this.renderStep() }

        </div>
      </Layout>
    )
  }
}

export default PanelPage;

export const pageQuery = graphql`
  query {
    allWcProductsCategories (
      filter : { slug : {nin: ["uncategorized", "non-classe"]} },
      sort: { fields: [count], order: DESC }
    ) {
      edges {
       node {
         id
         name
         display
         slug
         count
         description
         _links {
           self {
             href
           }
         }
         image{
           id
           src
           name
           alt
         }
       }
      }
    }
  }
`
