import React from 'react';

import { Row, Col } from '../layout/grid';
import { FormField, Button } from '../layout/form';
import STATES from '../../assets/files/states.json';

/**
 * @name PurchaseForm
 * form of customer purchasing and shipping details
 */
export default class PurchaseForm extends React.Component {

  render() {
    let { onSubmit, setBilling, setShipping, loading, errors } = this.props;
    // console.log(errors);
    return (
      <div className={`purchasing-container ${loading? 'purchasing-container_theme_dark': ''}`}>

        <form action="#" onSubmit={onSubmit}>
          <Row>
            <Col s='12'>
              <h2 className='purchasing-container__title mb0'>
                <span className='text-direction text-direction_left'>Informations d'acheteur</span>
                <span class='text-direction_right' dir='rtl'>معلومات المشتري</span>  
              </h2>
            </Col>
          </Row>
          <Row className="purchasing-container__customer-details">
            <FormField label='Nom*' labelAr='اللقب*' errorMessage={errors.first_name || null} placeholder='Nom' onChange={setBilling} name='first_name' parentClassName='col s12 m6 l4' autoFocus={true} />
            <FormField label='Prenom*' labelAr='الاسم*' errorMessage={errors.last_name || null} placeholder='Prenom' onChange={setBilling} name='last_name' parentClassName='col s12 m6 l4' />
            <FormField label='Numero Tel*' labelAr='رقم هاتف *' errorMessage={errors.phone || null} type='tel' placeholder='Numero Tel' onChange={setBilling} name='phone' parentClassName='col s12 m12 l4' />
          </Row>
          <Row className="purchasing-container__customer-details mb0">
            <FormField label='Adresse*' labelAr='عنوان*' errorMessage={errors.address_1 || null} placeholder='Adresse' onChange={setBilling} name='address_1' parentClassName='col s12 m12 l4' />
            <FormField label='Ville*' labelAr='مدينة*' placeholder='Ville' onChange={setBilling} name='city' parentClassName='col s12 m6 l4' />
            <div className='form-filed-block col s12 m6 l4'>
              <label for="wilaya" class="form-field-block__label">
                Wilaya*
                <span dir="rtl" class="text-direction_right">ولاية*</span>
              </label>
              <div className='form-fiel-block__input'>
                <select id='wilaya' name='state' className='form-field__select' onChange={setBilling}>
                  {STATES.map(({ code, nom, }) => <option value={nom}>{code} - {nom}</option>)}
                </select>
              </div>
            </div>
          </Row>
          <Row className="purchasing-container__customer-details">
            <FormField label='Adresse de livraison' labelAr='عنوان التسليم' placeholder='Adresse' onChange={setShipping} name='address_1' parentClassName='col s12 m12' />
            {
              /*
              <FormField label='Email' placeholder='email@adresse.com' onChange={setBilling} name='email' parentClassName='col s12 m6' />
              */
            }
          </Row>
          <Row>
            <Col s='12'>
              <Button
                disabled={loading}
                className='right product-ui__button-pulse'
                type='submit'>
                {
                  loading?
                    <>Attendez S.V.P <span className="dots-anime"></span></> :
                    <span>
                      <span>acheter maintenant</span>
                      &nbsp;<span className='text-direction_right'>اشتري الآن</span>
                    </span>
                }
               </Button>
            </Col>
          </Row>
        </form>

      </div>
    )

  }

}
