import React from 'react';
import { Link } from 'gatsby';

import {Row, Col} from '../layout/grid';
import {Button} from '../layout/form';

import EmptyBasketIcon from '../../assets/images/icons/shopping-basket.svg'

const ProductList = ({ data: productsList=[], onRemoveItem=null, onClick }) => {
  let total = 0;
  return (
    <div className="product-list">

      <ul className="product-list__container m0">

        {
          productsList.length?
            productsList.map( ({product_id, name, slug, image, price, regular_price, quantity, meta_data}, index) => {
              total += parseFloat(price, 10) * parseFloat(quantity, 10);
              return  (
                <li className="product-list__item" key={index}>
                  <Row>
                    <Col s='6' className='product-list__product-details-container'>
                      <Link to={`/produit/${slug}`}>
                        <div className="left product-list__image-container">
                          <img src={image} alt={`${name}`} className='product-list__image' />
                        </div>
                        <div className="left product-list__details">
                          <h2 className="product-list__name mb0">{name}</h2>
                          <div className="product-list__meta-data">
                            <div className="product-list__meta p0">
                              <strong className='product-list__meta-label capitalize'>Prix</strong>:&nbsp;
                              <strong className='product-list__meta-value capitalize'>{price} DA</strong>
                            </div>
                            {
                              meta_data.map(({label, value, key}) =>(
                                <div className="product-list__meta" key={key}>
                                  <strong className='product-list__meta-label capitalize'>{label.toLowerCase()}</strong>:&nbsp;
                                  <strong className='product-list__meta-value capitalize'>{value}</strong>
                                </div>
                              ))
                            }
                          </div>
                        </div>

                      </Link>
                    </Col>
                    <Col s='3' className='product-list__product-quantity-container'>
                      <div className="product-list__quantity">&times; {quantity}</div>
                    </Col>
                    <Col s='3' className='product-list__product-price-container'>
                      <div className="product-list__price left">
                        {price * quantity} DA
                      </div>
                      <button
                        onClick={e => {
                          e.preventDefault();
                          onRemoveItem(index)
                        }}
                        className="product-list__delete-product right">&times;</button>
                    </Col>
                  </Row>
                </li>
              )
            }) :
            (
              <li className="product-list__item empty-bascket">
                <Row>
                  <Col s='12' className='center'>
                    <div className="empty-basket">
                      <img src={EmptyBasketIcon} height={200} alt="panier est vide"/>
                    </div>
                    <h4>
                      Votre panier est vide, n'hesitez pas d'ajouter&nbsp;
                      <Link to="/nouveax-produits" title='Nouveaux produits'>des produits</Link>
                      &nbsp; ou retouner vers <Link to="/" title="page d'accueil">la page d'accueil</Link>.
                    </h4>
                  </Col>
                </Row>
              </li>
            )
        }

        {
          !!productsList.length &&
          (
            <>
            <li className="product-list__item">
              <Row>
                <Col s='12'>
                  <h2 className='capitalize center product-list__total-price right'>Total: {total} DA</h2>
                </Col>
              </Row>
            </li>
            <li className="product-list__item">
              <Row className='shipping-cost'>
                <Col s='12' style={{textAlign: 'right'}}>
                  <h2 className='capitalize product-list__total-price mb0'>Frais de livraison non inclus pour le moment</h2>
                  <p className="delivery-frees">Les frais de livraison sur Alger 400DA et hors wilaya 600DA.</p>
                </Col>
              </Row>
            </li>
            </>
          )
        }
      </ul>
      {
        !!productsList.length &&
        (

          <div style={{padding: '0 .75rem', height: 30}}>
            <Button className='right product-ui__button-pulse' onClick={onClick}>Finalisez votre commande</Button>
          </div>
        )
      }
      <br clear='both'/>
    </div>
  )
}

export default ProductList;
